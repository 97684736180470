<template>
    <v-container>
        <HeadbarPage :title="'Títulos > Alterar Agrupador em Massa'"></HeadbarPage>
        <UploadEmMassa
            @buscarTitulos="buscarTitulos"
            :title="'Alterar Agrupador de títulos em lote'"
            :textAreaLabel="'Cole aqui os títulos que deseja solicitar a alteração de Agrupador'"
            :textAreaHint="'Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5'"
            :instrucaoUpload="'Utilize o campo abaixo para fazer upload de arquivo com os títulos em massa para alteração de Agrupador'"
            :textAreaPlaceholder="'Cole aqui os títulos que deseja solicitar a alteração de Agrupador'"
            :dica="''"
        />
        <TableWithSelections 
            ref="tableWithSelections"
            :titulos="titulos"
            :totalTitulos="totalTitulos"
            :loading="loading"
            @selecionados="setSelecionados"
        />
        <CardConfirmacaoEnvioEmMassa 
            v-if="showChild"
            :key="childKey"
            ref="cardModal"
            :selecionados="selecionados"
            :avisoRetorno="avisoRetorno"
            :loadingFiltros="loadingFiltros"
            :resultadoCheck="resultadoCheck"
            :titulosEnviadosCheck="titulosEnviadosCheck"
            :type="'Agrupador'"
            :agrupadores="agrupadores"
            @enviar="alteracaoAgrupadorMassa"
        />
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="colorSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="snackbar = false"
        />
        
    </v-container>
</template>
<script>
import TituloService from '@/services/TituloService';
import Vue from 'vue';
import {
    listarAgrupadores,
} from '@/helpers/Utilitarios';
export default {
    name: 'AgrupadorEmMassaV2',
    components: {
        HeadbarPage: () => import('@/components/atoms/HeadbarPage'),
        UploadEmMassa: () => import('@/components/organisms/UploadEmMassa'),
        TableWithSelections: () => import('@/components/atoms/TableWithSelections'),
        CardConfirmacaoEnvioEmMassa: () => import('@/components/organisms/CardConfirmacaoEnvioEmMassa'),
    },
    data(){
        return{
            showChild: true,
            childKey: 0,
            snackbar: false,
            colorSnackbar: '',
            mensagemSnackbar: '',
            filtros: {
                cd_status: '',
            },
            tituloService: new TituloService(Vue.http, this.$store),
            options: {
                page: 1,
                itemsPerPage: 300
            },
            titulos: [],
            totalTitulos: 0,
            loading: false,
            selecionados: [],
            avisoRetorno: [],
            loadingFiltros: false,
            resultadoCheck: false,
            titulosEnviadosCheck: true,
            loadingAgrupador: false,
        }
    },
    computed: {
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        agrupadores() {
            return this.$store.getters.listaAgrupadoresFiltro;
        },
        isUsuarioAgrupador() {
            return this.$store.getters.isUsuarioAgrupador;
        },
        usuarioAdmin() {
            if (this.$store.getters.isAdmin == 1) {
                return true;
            }
            return false;
        }
    },
    async mounted() {
        if (this.usuarioAgrupador) {
            this.filtros.ds_agrupador = this.usuarioAgrupador;
        }
        await this.checarAgrupadores();
    },
    methods: {
        listarAgrupadores,
        async checarAgrupadores() {
            try {
                this.loadingAgrupador = true;
                await this.listarAgrupadores(this.$store, true);
            } catch (e) {
                console.log(e);
                this.snackbar = true;
                this.colorSnackbar = 'error';
                this.mensagemSnackbar = 'Erro ao buscar agrupadores';
            } finally {
                this.loadingAgrupador = false;
            }
        },
        setSelecionados(selecionados) {
            this.selecionados = selecionados;
        },
        resetChild() {
            this.showChild = false;
            this.$nextTick(() => {
                this.childKey += 1;
                this.showChild = true;
            });
        },
        formatarDocumento() {
            var nu_identificacao_devedor = this.filtros
                .nu_identificacao_devedor;
            if (nu_identificacao_devedor != null) {
                nu_identificacao_devedor = nu_identificacao_devedor.match(
                    /\d/g
                );
                nu_identificacao_devedor = nu_identificacao_devedor.join('');
                return nu_identificacao_devedor;
            } else {
                return null;
            }
        },
        async alteracaoAgrupadorMassa(titulos, novoAgrupador) {
            this.loading = true;
            this.avisoRetorno = [];
            this.resultadoCheck = false;
            this.titulosEnviadosCheck = true;
            this.tituloService.atualizarAgrupadorLote(
                titulos,
                novoAgrupador
            ).then(
                () => {
                    this.loading = false;
                    this.avisoRetorno = [];
                    this.resultadoCheck = false;
                    this.titulosEnviadosCheck = true;
                    this.snackbar = true;
                    this.colorSnackbar = 'success';
                    this.mensagemSnackbar = 'Agrupador alterado com sucesso';
                    this.resetChild()
                    this.$refs.tableWithSelections.resetSelecionados();
                    this.buscarTitulos(this.filtros);
                },
                error => {
                    this.loading = false;
                    this.avisoRetorno = error.body;
                    this.resultadoCheck = true;
                    this.titulosEnviadosCheck = false;
                    this.snackbar = true;
                    this.colorSnackbar = 'error';
                    this.mensagemSnackbar = 'Erro ao alterar agrupador';
                }
            );

        },
        buscarTitulos(filtros) {
            this.filtros = filtros;
            this.loading = true;
            this.selecionados = [];
            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            this.situacoesTitulo = this.$store.getters.situacoesTitulo;
            this.situacoesTitulo.push({
                cd_status: 99999,
                nm_status: 'EM REVISÃO'
            });
            this.filtros.cd_status = null,
            this.filtros.nu_identificacao_devedor = this.formatarDocumento();
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;
                try {
                    let service = new TituloService(Vue.http, this.$store);

                    service
                        .filtrarTitulos(this.filtros, page, itemsPerPage)
                        .then(
                            response => {
                                response.status;
                                response.statusText;
                                response.headers.get('Expires');
                                this.exportActive = false;
                                const {
                                    titulos,
                                    numero_linhas: total
                                } = response.body;

                                this.loadingFiltros = false;
                                this.loading = false;
                                this.titulos = titulos;
                                this.totalTitulos = total;
                                resolve();
                            },
                            error => {
                                this.loadingFiltros = false;
                                this.loading = false;
                                this.mensagem = error;
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
    }
}
</script>